<div class="modal-reports">
  <h2 class="modal-reports__title">Generar reporte de reasignaciones</h2>

  <div cdkFocusInitial tabindex="-1"></div>

  <form [formGroup]="reportsForm" class="modal-reports__form">
    <div class="modal-reports__inputs-row">
      <div class="modal-reports__date-inputs">
        <app-custom-date-picker
          [formControlName]="'startDate'"
          label="Fecha de inicio"
          placeholder="Selecciona la fecha"
          [maxDate]="maxDate"
          labelClass="custom-date-picker__label_start"
          mask="00/00/0000"
        />

        <app-custom-date-picker
          [formControlName]="'endDate'"
          label="Fecha fin"
          placeholder="Selecciona la fecha"
          [maxDate]="maxDate"
          [minDate]="reportsForm.get('startDate')?.value"
          labelClass="custom-date-picker__label_end"
          mask="00/00/0000"
        />
      </div>

      <mat-checkbox
        formControlName="totalReport"
        class="modal-reports__checkbox"
      >
        <div
          class="modal-reports__label_checkbox"
          [ngClass]="{ active: reportsForm.get('totalReport')?.value }"
        >
          Reporte total
        </div>
      </mat-checkbox>
    </div>
  </form>

  <div class="modal-reports__actions">
    <button
      class="modal-reports__button modal-reports__button--cancel"
      mat-dialog-close
    >
      Cancelar
    </button>
    <button
      class="modal-reports__button modal-reports__button--generate"
      (click)="onGenerateReport()"
      [disabled]="!isFormValid()"
    >
      Generar reporte
    </button>
  </div>
</div>
