import { inject, Injectable } from '@angular/core';
import { SalesReassignmentService } from './sales-reassignment.service';
import {
  DateRange,
  DateRangeRequest,
  DateRangeResponse,
} from '../interfaces/date-range';
import { catchError, map, Observable, of } from 'rxjs';
import moment from 'moment';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ReportGenerationService {
  private salesReassignmentSrv = inject(SalesReassignmentService);

  generateReport(
    isTotalReport: boolean,
    dateRange?: DateRangeRequest
  ): Observable<DateRange[] | null> {
    const reportObservable: Observable<DateRange[]> = isTotalReport
      ? this.salesReassignmentSrv.getAllReassignmentHistory()
      : this.salesReassignmentSrv
          .getReassignmentsByDateRange(dateRange!)
          .pipe(map((response: DateRangeResponse) => response.data));

    return reportObservable.pipe(
      map((data: DateRange[]) => {
        if (!data || data.length === 0) {
          return null;
        }
        return this.sortDataByDate(data);
      }),
      catchError((error) => {
        console.error('Error al obtener datos para el reporte:', error);
        return of(null);
      })
    );
  }

  private sortDataByDate(data: DateRange[]): DateRange[] {
    return data.sort((a, b) => {
      const dateA = moment(a.fecha_reasignacion);
      const dateB = moment(b.fecha_reasignacion);
      if (!dateA.isValid() || !dateB.isValid()) {
        return 0;
      }
      return dateB.valueOf() - dateA.valueOf();
    });
  }

  generateExcelFile(data: DateRange[]): void {
    try {
      const headerMapping: { [key: string]: string } = {
        numeroDocumento: 'Tercero (Id)',
        nombre_completo_prospecto: 'Nombres y apellidos del tercero reasignado',
        fecha_reasignacion: 'Fecha y hora de la reasignación',
        nombre_usuario_supervisor: 'Usuario que realizó la reasignación',
        nombre_usuario_anterior: 'Usuario comercial anterior',
        nombre_usuario_asignado: 'Usuario comercial nuevo',
        direccion_comercial_anterior: 'Anterior dirección comercial',
        direccion_comercial: 'Nueva dirección comercial',
      };

      const headersOrder = Object.values(headerMapping); // Mantén el orden de los headers

      const transformedData = data.map((item) => {
        const orderedItem: { [key: string]: any } = {};
        for (const key of Object.keys(headerMapping)) {
          // Reemplazar dirección_comercial_anterior por dirección_comercial si está vacío o nulo
          if (
            key === 'direccion_comercial_anterior' &&
            (!item[key as keyof DateRange] || item[key as keyof DateRange] === '')
          ) {
            orderedItem[headerMapping[key]] = item['direccion_comercial'];
          } else {
            orderedItem[headerMapping[key]] = item[key as keyof DateRange];
          }
        }
        return orderedItem;
      });

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData, {
        header: headersOrder,
      });

      worksheet['!cols'] = headersOrder.map((header) => ({
        wch: Math.max(header.length, 15),
      }));

      const workbook: XLSX.WorkBook = {
        Sheets: { data: worksheet },
        SheetNames: ['data'],
      };

      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveExcelFile(excelBuffer, 'reporte_reasignaciones.xlsx');
    } catch (error) {
      console.error('Error al generar el archivo Excel:', error);
    }
  }


  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;
    link.click();
  }
}
