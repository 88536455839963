import { MaterialModule } from './../../../../../shared/material.module';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DateRange, DateRangeRequest } from '../../interfaces/date-range';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import moment from 'moment';
import { A11yModule } from '@angular/cdk/a11y';
import { CustomDatePickerComponent } from '../material/custom-date-picker/custom-date-picker.component';
import { ReportGenerationService } from '../../services/report-generation.service';
import { MatDialogRef } from '@angular/material/dialog';
// Custom date formats
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM - YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-modal-reports',
  standalone: true,
  imports: [
    CommonModule,
    A11yModule,
    ReactiveFormsModule,
    MaterialModule,
    CustomDatePickerComponent,
  ],
  templateUrl: './modal-reports.component.html',
  styleUrl: './modal-reports.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ModalReportsComponent implements OnInit {
  reportsForm!: FormGroup;
  errorMessage = '';
  maxDate: moment.Moment = moment(); // Set max date to today
  updatingDates: boolean = false;

  private fb = inject(FormBuilder);
  private dateAdapter = inject(DateAdapter) as DateAdapter<moment.Moment>;
  private cdr = inject(ChangeDetectorRef);
  private reportGenerationService = inject(ReportGenerationService);
  private dialogRef = inject(MatDialogRef<ModalReportsComponent>);

  ngOnInit() {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => 0;
    this.customizeDayOfWeekNames();
    this.initForm();
    this.subscribeTotalReports();
    this.setupDateListeners();
    this.setupTotalReportListener();
  }

  private initForm() {
    this.reportsForm = this.fb.group(
      {
        startDate: [
          { value: '', disabled: false },
          [Validators.required, this.dateValidator.bind(this)],
        ],
        endDate: [
          { value: '', disabled: false },
          [Validators.required, this.dateValidator.bind(this)],
        ],
        totalReport: [false],
      },
      {
        validators: this.formValidator.bind(this), // Use AbstractControlOptions here
      }
    );
  }

  private setupTotalReportListener() {
    this.reportsForm
      .get('totalReport')
      ?.valueChanges.subscribe((isTotalReport) => {
        if (isTotalReport) {
          this.reportsForm.get('startDate')?.disable();
          this.reportsForm.get('endDate')?.disable();
        } else {
          this.reportsForm.get('startDate')?.enable();
          this.reportsForm.get('endDate')?.enable();
        }
        this.reportsForm.updateValueAndValidity();
        this.cdr.detectChanges();
      });
  }

  private formValidator(form: AbstractControl): ValidationErrors | null {
    const isTotalReport = form.get('totalReport')?.value;
    if (isTotalReport) {
      return null; // Form is valid if total report is selected
    }

    const startDate = form.get('startDate')?.value;
    const endDate = form.get('endDate')?.value;

    if (!startDate || !endDate) {
      return { datesMissing: true };
    }

    if (moment(endDate).isBefore(startDate)) {
      return { endDateBeforeStart: true };
    }

    return null;
  }

  private setupDateListeners() {
    this.reportsForm.get('startDate')?.valueChanges.subscribe((startDate) => {
      if (!this.updatingDates) {
        this.updatingDates = true;
        const endDate = this.reportsForm.get('endDate')?.value;

        if (endDate && moment(startDate).isAfter(endDate)) {
          this.reportsForm.get('endDate')?.setValue(null);
          this.reportsForm.get('endDate')?.updateValueAndValidity();
        }

        this.updateDateConstraints();
        this.updatingDates = false;
        this.cdr.detectChanges();
      }
    });

    this.reportsForm.get('endDate')?.valueChanges.subscribe(() => {
      if (!this.updatingDates) {
        this.updatingDates = true;
        this.updateDateConstraints();
        this.updatingDates = false;
        this.cdr.detectChanges();
      }
    });
  }

  private updateDateConstraints() {
    const startDate = this.reportsForm.get('startDate')?.value;
    const endDate = this.reportsForm.get('endDate')?.value;

    if (startDate) {
      this.reportsForm.get('endDate')?.enable();
      if (endDate && moment(endDate).isBefore(startDate)) {
        this.reportsForm.get('endDate')?.setValue(null);
        this.reportsForm.get('endDate')?.updateValueAndValidity();
      }
    } else {
      this.reportsForm.get('endDate')?.disable();
      this.reportsForm.get('endDate')?.setValue(null);
    }

    this.reportsForm.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private dateValidator(control: AbstractControl): ValidationErrors | null {
    const date = control.value;
    if (!date) {
      return null;
    }
    return moment(date).isAfter(this.maxDate) ? { futureDate: true } : null;
  }

  isFormValid(): boolean {
    const isTotalReport = this.reportsForm.get('totalReport')?.value;
    if (isTotalReport) {
      return true; // Form is valid if total report is selected
    }
    return this.reportsForm.valid;
  }

  private customizeDayOfWeekNames() {
    const narrowWeekdays = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

    // Use setLocale instead of setLocaleData
    this.dateAdapter.setLocale('es');

    // Set custom day names
    this.dateAdapter.getDayOfWeekNames('narrow').forEach((_, index) => {
      this.dateAdapter.getDayOfWeekNames('narrow')[index] =
        narrowWeekdays[index];
    });
  }

  private subscribeTotalReports() {
    this.reportsForm
      .get('totalReport')
      ?.valueChanges.subscribe(this.toggleDateFields.bind(this));
  }

  private toggleDateFields(isTotal: boolean) {
    const dateFields = ['startDate', 'endDate'];
    dateFields.forEach((field) => {
      isTotal
        ? this.reportsForm.get(field)?.disable()
        : this.reportsForm.get(field)?.enable();
    });
  }

  onGenerateReport() {
    if (this.reportsForm.invalid) return this.reportsForm.markAllAsTouched();

    const isTotalReport = this.reportsForm.get('totalReport')?.value;
    const dateRange = isTotalReport ? undefined : this.getDateRangeRequest();

    this.reportGenerationService
      .generateReport(isTotalReport, dateRange)
      .subscribe({
        next: (data: DateRange[] | null) => {
          if (data && data.length > 0) {
            this.reportGenerationService.generateExcelFile(data);
            this.dialogRef.close();
          }
          // Si no hay datos, simplemente no hacemos nada
        },
        error: (error) => {
          console.error('Error al generar el reporte:', error);
          // No mostramos ningún mensaje de error al usuario
        },
      });
  }

  private getDateRangeRequest(): DateRangeRequest {
    const startDate = this.reportsForm.get('startDate')?.value;
    const endDate = this.reportsForm.get('endDate')?.value;

    return {
      fecha_inicio: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      fecha_fin: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
    };
  }
}
