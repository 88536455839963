import { IDocumentType } from './../../../../shared/services/gestion-venta-prospecto/gestion.interface';
import {
  ProspectsRequest,
  ProspectsResponse,
} from './../interfaces/prospects-response';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdvisorsResponse } from '../interfaces/advisors-response';
import {
  UpdateUserToProspectRequest,
  UpdateUserToProspectResponse,
} from '../interfaces/update-user-to-prospect';
import {
  CreateReassignmentRequest,
  CreateReassignmentResponse,
} from '../interfaces/create-reassignment';
import {
  DateRange,
  DateRangeRequest,
  DateRangeResponse,
} from '../interfaces/date-range';
import { environmentApi, environment as ENV } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SalesReassignmentService {
  private _http = inject(HttpClient);
  private apiUrlReassignment = environmentApi.srvReassignment;
  private apiUrlCommercialPlant = environmentApi. srvCommercialPlant;

  getAdvisorsBySupervisor(
    puestoTrabajoSupervisor: number
  ): Observable<AdvisorsResponse[]> {
    const body = { puestoTrabajoSupervisor };
    return this._http.post<AdvisorsResponse[]>(
      `${this.apiUrlCommercialPlant}/obtenerUsuariosPorSupervisor`,
      body
    );
  }

  getRegistroPlantaPorCorreo(correo: string): Observable<AdvisorsResponse> {
    const body = { correo };
    return this._http.post<AdvisorsResponse>(
      `${this.apiUrlCommercialPlant}/obtenerRegistroPlantaPorCorreo`,
      body
    );
  }

  getProspectByUserEmail(userEmail: string): Observable<ProspectsResponse> {
    const url: string = `${ENV.api.host}${ENV.api.paths.prospect}${ENV.api.versions.v1}/getProspectByUserEmail`;
    const body = { userEmail };
    return this._http.post<ProspectsResponse>(url, body );
  }

  getProspectsByDocumentNumber(
    id_tipo_documento: number,
    numero_documento: string
  ): Observable<ProspectsResponse> {
    const url: string = `${ENV.api.host}${ENV.api.paths.prospect}${ENV.api.versions.v1}/listProspectsByDocumentNumber`;
    const body: ProspectsRequest = { id_tipo_documento, numero_documento };
    return this._http.post<ProspectsResponse>(url, body );
  }

  updateUserToProspect(
    body: UpdateUserToProspectRequest
  ): Observable<UpdateUserToProspectResponse> {
    const url: string = `${ENV.api.host}${ENV.api.paths.prospect}${ENV.api.versions.v1}/updateUserToProspect`;
    return this._http.post<UpdateUserToProspectResponse>(url, body );
  }

  getDocumentTypes(): Observable<IDocumentType[]> {
    const url: string = `${ENV.api.host}${ENV.api.paths.document_type}${ENV.api.versions.v1}/obtenerTipoDocumento`;
    return this._http.get<IDocumentType[]>(url);
  }

  createProspectSalesReassignment(
    body: CreateReassignmentRequest
  ): Observable<CreateReassignmentResponse> {
    return this._http.post<CreateReassignmentResponse>(
      `${this.apiUrlReassignment}/crearReasignacionVentaProspecto`,
      body
    );
  }

  // Srv para obtener reasignaciones por rango de fechas
  getReassignmentsByDateRange(
    body: DateRangeRequest
  ): Observable<DateRangeResponse> {
    return this._http.post<DateRangeResponse>(
      `${this.apiUrlReassignment}/obtenerReasignacionesByRangoFechas`,
      body
    );
  }

  // Srv para obtener el historial de reasignaciones
  getAllReassignmentHistory(): Observable<DateRange[]> {
    return this._http.get<DateRange[]>(`${this.apiUrlReassignment}/obtenerReasignacionesVentaProspectoConDocumento`);
  }
}
