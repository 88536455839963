import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl, Validators, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import moment from 'moment';

@Component({
  selector: 'app-custom-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatePickerComponent),
      multi: true
    }
  ]
})
export class CustomDatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() maxDate: moment.Moment = moment();
  @Input() minDate: moment.Moment = moment('2000-01-01');
  @Input() labelClass: string = '';
  @Input() mask!: string;
  disableRipple: boolean = false;
  dateControl: FormControl;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {
    this.dateControl = new FormControl('', [
      Validators.required,
      this.dateValidator.bind(this)
    ]);
  }

  ngOnInit() {
    this.dateControl.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  writeValue(value: any): void {
    if (value) {
      this.dateControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.dateControl.disable() : this.dateControl.enable();
  }

  private dateValidator(control: FormControl): { [key: string]: any } | null {
    const date = moment(control.value);
    if (!date.isValid()) {
      return { 'invalidDate': true };
    }
    if (date.isAfter(this.maxDate)) {
      return { 'futureDate': true };
    }
    if (date.isBefore(this.minDate)) {
      return { 'pastDate': true };
    }
    return null;
  }
}
